import { Layout } from 'components/Layout';
import { PrincipleBenefits } from 'components/Principle/Benefits';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ButtonGroup } from 'src/components/ButtonGroup';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { Paragraph } from 'src/components/Paragraph';
import { PrincipleHero } from 'src/components/Principle/Hero';
const query = graphql`
    query FoundationQuery {
        hero: file(relativePath: { eq: "images/hero/foundation.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        suite: file(
            relativePath: { eq: "images/foundation/software-tools.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        enterprise: file(
            relativePath: { eq: "images/foundation/hosting.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        accessibility: file(
            relativePath: { eq: "images/foundation/access-community.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        organize: file(
            relativePath: { eq: "images/foundation/organize-community.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bigChange: file(
            relativePath: { eq: "images/foundation/bigchange-community.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        optimizing: file(
            relativePath: { eq: "images/foundation/optimizing-community.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        dsa: file(
            relativePath: {
                eq: "images/digital-services-act/moving-forward.png"
            }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default function FoundationPage() {
    const data = useStaticQuery(query);

    return (
        <Layout
            pageTitleFull="Foundation: An Audentio Community Principle"
            siteDescription="The software and tools that power your online community are the foundation. Find the right fit with Audentio."
            contactForm
            subnavTitle="Foundation"
            subnav={[
                {
                    label: 'Features',
                    id: 'features',
                },
                {
                    label: 'Technology',
                    id: 'technology',
                },
                {
                    label: 'Benefits',
                    id: 'benefits',
                },
                {
                    label: 'Hosting',
                    id: 'hosting',
                },
            ]}
        >
            <PrincipleHero
                title="Find simplicity and reliability in your tech-setup"
                subtitle="Foundation"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="foundation" />

            <PrincipleFeatures
                id="features"
                title="establish a scalable, secure, and reliable community"
                features={[
                    {
                        subtitle: 'strategy',
                        title: 'assigning roles and permissions',
                        content:
                            'Segment your users for a better understanding of your community via analytics and align permissions based on user validation and trustworthiness, monetization strategies, or other criteria.',
                    },
                    {
                        subtitle: 'setup',
                        title: 'setup hosting infrastructure',
                        content:
                            'Know what type of hosting options you have, what you want, who will host it, how it is managed, and ensuring it is optimized for you.',
                    },
                    {
                        subtitle: 'tech',
                        title: 'choosing the right tools',
                        content:
                            "There are likely a lot of add-ons, extensions, plugins, and themes to use but your community doesn't need every bell and whistle, it could actually hurt it. Pick the tools you need with care.",
                    },
                    {
                        subtitle: 'strategy',
                        title: 'dataflow mapping',
                        content:
                            'Understanding how each piece of your community works together is critical for any community, especially larger communities or those looking to scale.',
                    },
                    {
                        subtitle: 'setup',
                        title: 'platform evaluation and selection',
                        content:
                            'There are many platform options available all with varying benefits, disadvantages, and ideal use cases. Take the time to think through your needs and long term plans and ask for guidance where needed.',
                    },
                    {
                        subtitle: 'tech',
                        title: 'integration assessment',
                        content:
                            "Too many moving parts or barriers? Identify areas to streamline or software that's causing challenges and assess the pros/cons of integrations.",
                    },
                ]}
            />

            {/* <CTA
                title="Ready to build a community?"
                position="relative"
                marginBottom={{ _: 0, full: '-58px' }}
            /> */}

            <MediaSection
                id="technology"
                paddingTop={{ _: '6rem', lg: '6rem', full: '15rem' }}
                mb={0}
                image={data.suite.childImageSharp.fluid}
                title="Determining what's right for you"
            >
                There isn’t an all-in-one solution when it comes to building
                your community. Your ideal solution will utilize multiple tools
                to create an experience for your community that will continue to
                grow with you.
            </MediaSection>

            <PrincipleBenefits
                id="benefits"
                mt={0}
                title="How foundation can benefit your community"
                // ctaHref="/contact?source=foundation"
                // ctaLabel="Contact us"
                benefits={[
                    'utilize the best platform and integrations for your community',
                    'establish a hosting infrastructure that meets your needs',
                    'easily handle up-keep and implement future features',
                ]}
            />

            {/* <MediaSection
                id="hosting"
                image={data.enterprise.childImageSharp.fluid}
                title="Enterprise-grade scalability & security. Startup-friendly prices."
            >
                Hosting is one of the least interesting, yet important,
                decisions you have to make for your community. We take care of
                it, providing you a hosting solution for your community that
                will meet your needs at every point in your community's life.
            </MediaSection> */}

            <MediaSection
                flipped
                title="With the Digital Services Act, change could be coming to your community"
                image={data.dsa.childImageSharp.fluid}
            >
                <Paragraph pb="spacing">
                    Since the Digital Services Act (DSA) and Digital Markets Act
                    (DMA) were approved in March 2022, it's best to remain
                    informed and determine how these regulations could impact
                    your platform. We've done the research for you and
                    highlighted the key takeaways to be aware of.
                </Paragraph>
                <ButtonGroup
                    buttons={[
                        {
                            text: 'Learn More',
                            href: '/landing/digital-services-act/',
                        },
                    ]}
                    pt={6}
                />
            </MediaSection>

            <LandingFeatureGrid
                center
                sectionHeaderProps={{
                    direction: 'row',
                }}
                offset={false}
                flexDirection="column"
                title="Related articles"
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                featureGridProps={{
                    mx: 0,
                    px: 0,
                    columns: [1, 2, null, 4],
                    spacing: {
                        _: 'spacing-lg',
                    },
                }}
                showContact={false}
                items={[
                    {
                        image: data.accessibility.childImageSharp.fluid,
                        heading:
                            'Things you may not have thought of to make your community as accessible as possible',
                        content:
                            'If you aren’t thinking about website accessibility, you should be. Everyone should have at least a basic level of accessibility enabled on...',
                        href:
                            'https://adjourn.audent.io/things-you-may-not-have-thought-of-to-make-your-community-as-accessible-as-possible-34b9dffeda74',
                    },
                    {
                        image: data.organize.childImageSharp.fluid,
                        heading:
                            'Organizing your community topics and forum structure',
                        content:
                            'The space you provide for discussions is a critical part of your community’s strength as a platform. Creating too many separate topics causes confusion...',
                        href:
                            'https://adjourn.audent.io/organizing-your-community-topics-and-forum-structure-b49b1a312207',
                    },
                    {
                        image: data.optimizing.childImageSharp.fluid,
                        heading: 'Optimizing for XenForo',
                        content:
                            'Throughout our history of working with community platforms, we’ve worked to optimize the performance of websites and improve the user experience.',
                        href:
                            'https://adjourn.audent.io/optimization-and-speed-improvements-to-xenforo-30c2a3f45741',
                    },
                    {
                        image: data.bigChange.childImageSharp.fluid,
                        heading:
                            'What makes for a successful upgrade, migration, or big change to your community',
                        content:
                            'Change can be tough for many members of your community. Having the right strategy in place will help with the overall transition and...',
                        href:
                            'https://adjourn.audent.io/what-makes-for-a-successful-upgrade-migration-or-big-change-to-your-community-897aa44d3df7',
                    },
                ]}
            />
        </Layout>
    );
}
